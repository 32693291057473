export const de = {
  p: {
    reference: 'Referenz | Referenzen',
    annotation: 'Anmerkung | Anmerkungen',
    entity: 'Entität | Entitäten',
    task: 'Aufgabe | Aufgaben',
    contentBlockLocalization: 'Inhaltsblock Lokalisierung | Inhaltsblock Lokalisierungen',
    inlineResource: 'Inline Ressource | Inline Ressourcen',
    textBox: 'Text Box | Text Boxen',
    titlePage: 'Titelseite | Titelseiten',
    structure: 'Struktur | Strukturen',
    page: 'Seite | Seiten',
    relevantLO: 'Relevantes Lernziel | Relevante Lernziele',
    irrelevantLO: 'Irrelevantes Lernziel | Irrelevante Lernziele',
    explanation: 'Erklärung | Erklärungen',
    proseBlock: 'Prosa Block | Prosa Blöcke',
    internalComment: 'Interner Kommentar | Interne Kommentare',
    publicComment: 'Öffentlicher Kommentar | Öffentliche Kommentare',
    draft: 'Entwurf | Entwürfe',
    pdf: 'PDF | PDFs',
    audioFile: 'Audio Datei | Audio Dateien',
    second: 'Sekunde | Sekunden',
    document: 'Dokument | Dokumente',
    country: 'Land | Länder',
    invite: 'Einladung | Einladungen',
    filter: 'Filter | Filter',
    overdueOrder: 'Überfällige Bestellung | Überfällige Bestellungen',
    subTotal: 'Zwischensumme | Zwischensummen',
    discount: 'Rabatt | Rabatte',
    tax: 'Steuer | Steuern',
    purchase: 'Kauf | Käufe',
    item: 'Artikel | Artikel',
    product: 'Produkt | Produkte',
    questionCatalogue: 'Fragenkatalog | Fragenkataloge',
    detail: 'Detail | Details',
    child: 'Kind | Kinder',
    parent: 'Elternteil | Eltern',
    examinationAuthority: 'Prüfungsbehörde | Prüfungsbehörden',
    relation: 'Relation | Relationen',
    type: 'Typ | Typen',
    educationType: 'Ausbildungs-Typ | Ausbildungs-Typen',
    course: 'Kurs | Kurse',
    questionSource: 'Fragenquelle | Fragenquellen',
    accessPass: 'Zugangspass | Zugangspässe',
    education: 'Ausbildung | Ausbildungen',
    day: 'Tag | Tage',
    week: 'Woche | Wochen',
    month: 'Monat | Monate',
    year: 'Jahr | Jahre',
    account: 'Zugang | Zugänge',
    partner: 'Partner | Partner',
    platform: 'Platform | Platformen',
    review: 'Review | Reviews',
    weighting: 'Gewichtung | Gewichtungen',
    minute: 'Minute | Minuten',
    correctAnsweredQuestion: 'Richtig Beantwortete Frage | Richtig Beantwortete Fragen',
    incorrectAnsweredQuestion: 'Falsch Beantwortete Frage | Falsch Beantwortete Fragen',
    answeredQuestion: 'Beantwortete Frage | Beantwortete Fragen',
    unansweredQuestion: 'Unbeantwortete Frage | Unbeantwortete Fragen',
    message: 'Nachricht | Nachrichten',
    solution: 'Lösung | Lösungen',
    availableQuestion: 'Verfügbare Frage | Verfügbare Fragen',
    learningObjectiveTree: 'Lernzielbaum | Lernzielbäume',
    warning: 'Warnung | Warnungen',
    error: 'Fehler | Fehler',
    questionnaireTemplate: 'Befragungsvorlage | Befragungsvorlagen',
    questionnaire: 'Befragung | Befragungen',
    examination: 'Prüfung | Prüfungen',
    point: 'Punkt | Punkte',
    statistic: 'Statistik | Statistiken',
    question: 'Frage | Fragen',
    answer: 'Antwort | Antworten',
    organizationProfile: 'Organisationsprofil | Organisationsprofile',
    userProfile: 'Benutzerprofil | Benutzerprofile',
    authorProfile: 'Autorenprofil | Autorenprofile',
    contactProfile: 'Kontaktprofil | Kotaktprofile',
    eLearningAccount: 'eLearning Zugang | eLearning Zugänge',
    organizationAccount: 'Organisationszugang | Organisationszugänge',
    ltmsAccount: 'LTMS Zugang | LTMS Zugänge',
    media: 'Medien | Medien',
    resource: 'Ressource | Ressourcen',
    rating: 'Bewertung | Bewertungen',
    version: 'Version | Versionen',
    student: 'Schüler | Schüler',
    author: 'Autor | Autoren',
    licence: 'Lizenz | Lizenzen',
    attachment: 'Anhang | Anhänge',
    comment: 'Kommentar | Kommentare',
    contentBlock: 'Inhaltsblock | Inhaltsblöcke',
    script: 'Skript | Skripte',
    content: 'Inhalt | Inhalte',
    change: 'Änderung | Änderungen',
    user: 'Benutzer | Benutzer',
    organization: 'Organisation | Organisationen',
    language: 'Sprache | Sprachen',
    profile: 'Profil | Profile',
    order: 'Bestellung | Bestellungen',
    invoice: 'Rechnung | Rechnungen',
    notification: 'Benachrichtung | Benachrichtungen',
    file: 'Datei | Dateien',
    note: 'Notiz | Notizen',
    name: 'Name | Namen',
    tag: 'Tag | Tags',
    ticket: 'Ticket | Tickets',
    image: 'Bild | Bilder',
    video: 'Video | Videos',
    audio: 'Audio | Audios',
    url: 'URL | URLs',
    source: 'Quelle | Quellen',
    learningObjective: 'Lernziel | Lernziele',
    event: 'Event | Events',
    todo: 'Todo | Todos',
    translation: 'Übersetzung | Übersetzungen',
  },
  field: {
    reviewedAt: 'Geprüft am',
    origin: 'Ursprung',
    isArchived: 'Archiviert',
    contactEmail: 'Email (Kontakt)',
    supportEmail: 'Email (Support)',
    isScriptRelevant: 'Skriptrelevant',
    reducedTax: 'Reduzierte Steuer',
    vatExempt: 'MwSt befreit',
    orderStatus: 'Bestellstatus',
    invoiceStatus: 'Rechnungsstatus',
    customerNumber: 'Kundennummer',
    taxId: 'Steuer-ID',
    tax: 'Steuer',
    recipient: 'Empfänger',
    allowInvoice: 'Rechnung erlaubt',
    discount: 'Rabatt',
    price: 'Preis',
    jobTitle: 'Job-Titel',
    website: 'Webseite',
    certificateNumber: 'Zertifikat #',
    primaryContact: 'Kontaktperson',
    gender: 'Geschlecht',
    licenceType: 'Lizenztyp',
    educationStartDate: 'Ausbildungsbeginn',
    educationEndDate: 'Ausbildungsende',
    certificateDate: 'Zertifizierungsdatum',
    preliminaryCertificateDate: 'Vorläufiges Zertifizierungsdatum',
    publishedState: 'Veröffentlichungszustand',
    birthDate: 'Geburtstag',
    tokenVersion: 'Token Version',
    lastLoginAt: 'Letzte Aktivität',
    phone: 'Telefon',
    fax: 'Fax',
    position: 'Position',
    login: 'Login',
    id: 'Id',
    sourceReference: 'Quellen Referenz',
    name: 'Name',
    number: 'Nummer',
    username: 'Benutzername',
    note: 'Notiz',
    updatedAt: 'Geändert am',
    updatedBy: 'Geändert von',
    createdAt: 'Erstellt am',
    startedAt: 'Angefangen am',
    endedAt: 'Beendet am',
    actions: 'Aktionen',
    email: 'Email',
    personalEmail: 'Email (privat)',
    addressLine1: 'Adresszeile 1',
    addressLine2: 'Adresszeile 2',
    password: 'Passwort',
    passwordConfirm: 'Passwort bestätigen',
    date: 'Datum',
    data: 'Daten',
    type: 'Typ',
    user: 'Benutzer',
    logo: 'Logo',
    image: 'Bild',
    object: 'Objekt',
    subject: 'Subjekt',
    language: 'Sprache',
    description: 'Beschreibung',
    title: 'Titel',
    subTitle: 'Untertitel',
    content: 'Inhalt',
    title_: 'Titel {suffix}',
    text: 'Text',
    source: 'Quelle',
    body: 'Inhalt',
    headline: 'Kopfzeile',
    firstName: 'Vorname',
    lastName: 'Nachname',
    country: 'Land',
    state: 'Bundesland',
    streetName: 'Straßenname',
    streetAddress: 'Hausnummer',
    phoneNr: 'Telefonnummer',
    zipCode: 'PLZ',
    city: 'Stadt',
    assignedUser: 'Zugew. Nutzer',
    creator: 'Ersteller',
    picture: 'Bild',
    headlineLevel: 'Kopfzeilen-Einrückung',
    studyTime: 'Study Time',
    status: 'Status',
    version: 'Version',
    avatar: 'Avatar',
    isCorrect: 'Korrekt',
    isRepeatEducation: 'Wiederholer',
  },
  label: {
    pageBreak: 'Seitenumbruch',
    none: 'Keins',
    myTasks: 'Meine Aufgaben',
    createdByMe: 'Von Mir erstellt',
    paragraphMarks: 'Paragraphen-Markierungen',
    sideImageTemplate: 'Vorlage für Seitenbild',
    contentBlockList: 'Inhaltsblöcke die dieses Lernziel behandeln:',
    applicableLicences: 'Anwendbar für Lizenzen',
    reverseCharge: 'Reverse Charge',
    reviewedBy: 'geprüft von',
    draftList: 'Entwurfsliste',
    forApproval: 'zur Freigabe',
    pendingDraft: 'Ausstehender Entwurf',
    reviewer: 'Prüfer',
    deleted: 'Gelöscht',
    detailView: 'Detailansicht',
    hasDraft: 'Hat Vorschlag',
    exclude: 'Ausschließen',
    include: 'Einschließen',
    only: 'Nur',
    external: 'Extern',
    resetRating: 'Bewertung zurücksetzen',
    public: 'Öffentlich',
    emailLanguage: 'Email Sprache',
    at: 'am',
    for: 'für',
    createdBy: 'Erstellt von',
    versionList: 'Versionsliste',
    currentVersion: 'Aktuelle Version',
    newVersion: 'Neue Version',
    assignedBy: 'Zugeordnet von',
    assignedAt: 'Zugeordnet am',
    activatedAt: 'Aktiviert am',
    system: 'System',
    myProducts: 'Meine Produkte',
    myAccessPasses: 'Meine Zugangspässe',
    createdFrom: 'Erstellt ab',
    createdUntil: 'Erstellt bis',
    minTotal: 'Minimum Preis',
    maxTotal: ' Maximum Preis',
    client: 'Auftraggeber',
    clientIsNotRecipient: 'Auftraggeber ist nicht Empfänger',
    inventory: 'Inventar',
    amount: 'Menge',
    assigned: 'Zugewiesen',
    activated: 'Aktiviert',
    orderDate: 'Bestelldatum',
    billingAddress: 'Rechnungsadresse',
    paymentDetails: 'Zahlungsdetails',
    paymentMethod: 'Zahlungsart',
    assignedItems: 'Zugewiesene {items}',
    availableItems: 'Verfügbare {items}',
    filter: 'Filter',
    origin: 'Ursprung',
    total: 'Insgesamt',
    progressOverview: 'Fortschrittsübersicht',
    processed: 'Bearbeitet',
    feedbackType: 'Art des Feedbacks',
    imprint: 'Impressum',
    current: 'Aktuell',
    lastSubmission: 'Letzte Abgabe',
    interfaceLanguage: 'Oberflächensprache',
    analysis: 'Auswertungen',
    validity: 'Gültigkeit',
    validFrom: 'Gültig ab',
    validUntil: 'Gültig bis',
    expiresAt: 'Gültig bis',
    startDate: 'Startdatum',
    endDate: 'Enddatum',
    cancelDate: 'Abbruchsdatum',
    trainingStartDate: 'Trainingsbeginn',
    trainingEndDate: 'Trainingsende',
    eLearning: 'eLearning',
    performance: 'Performance',
    isActive: 'Aktiv',
    account: 'Account',
    selectedItems: '{items} ausgewählt',
    totalItems: '{items} insgesamt',
    endedQuestionnaireInstances: 'Beendete Befragungen',
    activeQuestionnaireInstances: 'Aktive Befragungen',
    tableOfContents: 'Inhaltsverzeichnis',
    reasonForChange: 'Änderungsgrund',
    duration: 'Dauer',
    overview: 'Übersicht',
    feedback: 'Feedback',
    timeLimit: 'Zeitlimit',
    settings: 'Einstellungen',
    selection: 'Auswahl',
    questionSelection: 'Fragenauswahl',
    summary: 'Übersicht',
    training: 'Training',
    changeHistory: 'Änderungshistorie',
    filename: 'Dateiname',
    preview: 'Vorschau',
    searchIn: 'Suchen in',
    isExternal: 'Wird Extern gehostet',
    details: 'Details',
    contact: 'Kontakt',
    today: 'Heute',
    calendar: 'Kalender',
    info: 'Info',
    pdf: 'Pdf',
    all: 'Alle',
    assignedToMe: 'Mir zugewiesen',
    fromMe: 'Von Mir',
    questionTrainer: 'Fragentrainer',
    oClock: 'Uhr',
    never: 'Nie',
    count: 'Anzahl',
    by: 'von',
  },
  calendarType: {
    day: 'Tag',
    week: 'Woche',
    month: 'Monat',
    '4day': '4 Tage',
  },
  placeholder: {
    myNotice: 'Meine Notiz...',
    myFeedback: 'Mein Feedback...',
  },
  action: {
    seeImgName: '[Siehe Bild {name}]',
    seeImg: '[Siehe Bild]',
    toggleItem: '{item} ein-/ausschalten',
    togglePreventItem: '{item} verhindern/zulassen',
    insertItem: '{item} einfügen',
    referenceItem: 'Auf {item} verweisen',
    inviteUser: 'Benutzer einladen',
    sendInvite: 'Einladung verschicken',
    searchFor: 'Suche nach',
    expandAll: 'Alle ausklappen',
    collapseAll: 'Alle einklappen',
    collapse: 'Einklappen',
    expand: 'Ausklappen',
    hideUnusedCBs: 'Nicht verwendete Inhaltsblöcke ausblenden.',
    showUnusedCBs: 'Nicht verwendete Inhaltsblöcke einblenden.',
    reload: 'Neuladen',
    refresh: 'Aktualisieren',
    accept: 'Freigeben',
    reject: 'Ablehnen',
    rejectDraft: 'Entwurf Ablehnen',
    submitDraft: 'Entwurf einreichen',
    open: 'Öffnen',
    download: 'Herunterladen',
    browseItems: '{items} anzeigen',
    addItemToTarget: '{item} zu "{target}" hinzufügen',
    create: 'Erstellen',
    executeOrder: 'Bestellung abschließen',
    ok: 'OK',
    activate: 'Aktivieren',
    confirmAssignment: 'Zuweisung Bestätigen',
    copyToClipboard: 'In die Zwischenablage kopieren',
    copyItemToClipboard: '{item} in die Zwischenablage kopieren',
    orderAtNoCost: 'Kostenlos bestellen',
    remove: 'Entfernen',
    reset: 'Zurücksetzen',
    exitItem: '{item} verlassen',
    submitFeedback: 'Feedback abgeben',
    provideFeedbackForItem: 'Problem bei {item} melden',
    selectNewPassword: 'Neues Passwort festlegen',
    resetPassword: 'Passwort zurücksetzen',
    apply: 'Anwenden',
    filter: 'Filtern',
    unbookmarkItem: '{item} vergessen',
    bookmarkItem: '{item} merken',
    add: 'Hinzufügen',
    updateItem: '{item} aktualisieren',
    requestItem: '{item} anfragen',
    hideItem: '{item} verbergen',
    checkItem: '{item} überprüfen',
    writeItem: '{item} schreiben',
    scaleUp: 'Vergrößern',
    scaleDown: 'Verkleinern',
    openInNewTab: 'In neuem Tab öffnen',
    importFrom: 'Von {from} importieren',
    select: 'Auswählen',
    end: 'Beenden',
    endItem: '{item} beenden',
    removeItem: '{item} entfernen',
    save: 'Speichern',
    saveAndEnd: 'Speichern und Beenden',
    dontSaveAndEnd: 'Beenden ohne zu Speichern',
    saveAs: 'Speichern als {as}',
    check: 'Prüfen',
    start: 'Starten',
    prev: 'Zurück',
    next: 'Weiter',
    import: 'Importieren',
    upload: 'Hochladen',
    uploadItem: '{item} hochladen',
    update: 'Aktualisieren',
    reply: 'Antworten',
    close: 'Schließen',
    insert: 'Einfügen',
    seeItem: '{item} ansehen',
    addItem: '{item} hinzufügen',
    selectItem: '{item} auswählen',
    selectAll: 'Alle auswählen',
    createItem: '{item} erstellen',
    translateItem: '{item} übersetzen',
    changeItem: '{item} ändern',
    editItem: '{item} bearbeiten',
    deleteItem: '{item} löschen',
    importItem: '{item} importieren',
    enableItem: '{item} aktivieren',
    openItem: '{item} öffnen',
    showItem: '{item} anzeigen',
    generateItem: '{item} generieren',
    cancelItem: '{item} abbrechen',
    switchLanguage: 'Sprache ändern',
    edit: 'Bearbeiten',
    submit: 'Absenden',
    confirm: 'Bestätigen',
    cancel: 'Abbrechen',
    logout: 'Abmelden',
    login: 'Anmelden',
    delete: 'Löschen',
    back: 'Zurück',
    search: 'Suchen',
    feedback: 'Feedback',
    resetFilter: 'Filter "{query}" zurücksetzen',
    selected: 'ausgewählt',
    moveItemUp: '{item} nach oben verschieben',
    moveItemDown: '{item} nach unten verschieben',
  },
  change: {
    by: {
      delete: 'Gelöscht von',
      update: 'Geändert von',
      create: 'Erstellt von',
    },
  },
  notify: {
    success: 'Erfolg',
    error: 'Fehler',
    info: 'Info',
    serverError: 'Server Fehler',
    clientError: 'Client Fehler',
    unauthorized: 'Nicht Authorisiert',
    unknownError: 'Unbekannter Fehler',
    unknownEmail: 'Unbekannte Email Adresse',
    changeItemSuccess: '{item} wurde erfolgreich geändert.',
    changeItemFail: '{item} konnte nicht geändert werden.',
    createItemSuccess: '{item} wurde erfolgreich erstellt.',
    addItemsSuccess: '{items} wurden erfolgreich hinzugefügt.',
    addItemSuccess: '{item} wurde erfolgreich hinzugefügt.',
    removeItemsSuccess: '{items} wurden erfolgreich entfernt.',
    removeItemSuccess: '{item} wurde erfolgreich entfernt.',
    updateItemSuccess: '{item} wurde erfolgreich bearbeitet.',
    updateItemsSuccess: '{items} wurden erfolgreich bearbeitet.',
    deleteItemSuccess: '{item} wurde erfolgreich gelöscht.',
    saveItemSuccess: '{item} wurde erfolgreich gespeichert.',
    contentBlocksRemovedFromSelection: '{count} Objekt wurde aus der Auswahl entfernt, da es bereits in der Struktur vorhanden ist. | {count} Objekte wurden aus der Auswahl entfernt, da sie bereits in der Struktur vorhanden sind.',
    contentBlocksAddedToNode: '{count} Eintrag wurde "{node}" hinzugefügt. | {count} Einträge wurden "{node}" hinzugefügt.',
    contentBlocksAddedToRoot: '{count} Eintrag wurde der Struktur hinzugefügt. | {count} Einträge wurden der Struktur hinzugefügt.',
  },
  language: {
    global: 'Global',
    de: 'Deutsch',
    en: 'Englisch',
    ro: 'Rumänisch',
    si: 'Slowenisch',
    nl: 'Niederländisch',
    fr: 'Französisch',
  },
  email: {
    sendNotification: 'per E-Mail benachrichtigen',
    notificationText: 'Benachrichtigungstext',
    template: {
      createUser: `Hallo {{userName}},
Du kannst dich auf {{url}} mit den folgenden Zugangsdaten anmelden:

Email: {{email}}
Passwort: {{password}}

Viel Spaß!`,
    },
  },
  common: {
    notYetOnline: 'Noch nicht online',
    isUploading: 'wird hochgeladen...',
  },
  changelog: {
    action: {
      create: 'hat {0} {1} erstellt',
      update: 'hat {0} {1} geändert',
      remove: 'hat {0} {1} gelöscht',
    },
  },
  dialog: {
    changePassword: {
      title: 'Passwort Ändern',
      text: 'Das neue Passwort muss aus mindestens {minLength} Zeichen bestehen',
    },
    delete: {
      title: 'Löschen bestätigen',
      text: 'Bist du dir sicher, dass du dieses Element löschen willst?',
    },
    feedback: {
      title: 'Gib Feedback',
      text: 'Erstelle ein Ticket und beschreibe dein Anliegen',
    },
    version: {
      title: 'Änderung Speichern',
      text: 'Handelt es sich bei dieser Änderung um ein Major-, Minor-, oder Patch-Level Update?',
    },
    confirmLoDeselect: {
      title: 'Verbindung aufheben',
      text: 'Bist du sicher, dass du die Verbindung zu dem Lernziel "{lo}" und allen darunter liegenden Lernzielen aufheben möchtest?',
    },
    changeDraft: 'Möchtest du den Entwurf wirklich ändern?\n\nDiese Änderung kann nicht rückgängig gemacht werden.',
    removeContentBlock: {
      title: 'Inhaltsblock entfernen',
      text: 'Bist du dir sicher, dass du den Inhaltsblock "{cb}" und alle enthaltenen Inhaltsblöcke entfernen möchtest?',
    },
    unsavedChanges: {
      title: 'Ungespeicherte Änderung',
      text: 'Dieser Inhaltsblock enthält ungespeicherte Änderungen.\n\nSollen diese Änderungen wirklich verworfen werden?',
    },
  },
  versionType: {
    major: {
      title: 'Major Change',
      text: 'Inhalt wurde neu erstellt oder komplett überarbeitet. Änderung des Lernziels.',
    },
    minor: {
      title: 'Minor Change',
      text: 'Inhaltliche Änderung oder Korrektur. Hinzufügen, ändern oder entfernen von Ressourcen.',
    },
    fix: {
      title: 'Fix',
      text: 'Grammatikalische oder stilistische Aufwertung ohne Änderung des Inhalts. Änderung von Stammdaten. Änderung von Lizenzen. Hinzufügen neuer Lokalisierungen.',
    },
    draft: {
      title: 'Draft',
      text: 'Änderung als Entwurf zur Freigabe einreichen.',
    },
  },
  msg: {
    contentBlockHasNoRelatedQuestions: 'Dieser Inhaltsblock behandelt kein Lernziel dem Fragen zugeordnet sind.',
    contentBlockHasNoRelatedLearningObjectives: 'Diesem Inhaltsblock sind keine Lernziele zugeordnet.',
    noChangesAvailable: 'Keine Änderungen vorhanden',
    addUserAndSendInvite: 'Benutzer anlegen und per Email benachrichtigen',
    cbHasExcessiveLOs: 'Dieser Inhaltsblock behandelt mindestens ein Lernziel, das für die ausgewählte Lizenz nicht relevant ist.',
    pendingDraft: 'Diese Entität kann nicht bearbeitet werden, bis der ausstehende Entwurf freigegeben oder abgelehnt wurde.',
    notYetRated: 'Inhalt bisher nicht bewertet',
    questionArchived: 'Diese Frage wurde archiviert.',
    questionArchivedText: `Diese Frage wurde archiviert nachdem diese Befragung erstellt wurde.

Sie kann nicht mehr beantwortet oder in neuen Befragungen wiederholt werden und trägt nicht mehr zum Gesamtfortschritt bei.`,
    pageNotFound: 'Die Seite "{page}" konnte nicht gefunden werden',
    passwordChangeSuccess: 'Das Passwort wurde erfolgreich geändert',
    noItemsAvailable: 'Keine {items} verfügbar',
    noDataAvailable: 'Keine Daten vorhanden',
    resetPasswordLinkSentToEmail: 'Link zum Zurücksetzen wurde an {email} gesendet',
    organizationCannotPlaceOrders: 'Diese Organisation wird keine zahlungspflichtigen Bestellungen aufgeben können, bis die benötigten Adressdaten eingetragen sind.<br><br>Trotzdem {action}?',
    organizationCountryWarning: 'Diese Organisation wird keine zahlungspflichtigen Bestellungen aufgeben können, da das ausgewählte Land nicht aktiviert ist.<br><br>Trotzdem {action}?',
    organizationMissingTaxIdWarning: 'Organisationen ohne Steuer ID können keine zahlungspflichtigen Bestellungen aufgeben, falls sie sich in einem Land mit Reverse Charge verfahren befinden.<br><br>Organisation trotzdem ohne Steuer ID {action}?',
    noDraftsSubmitted: 'Es wurden noch keine Entwürfe eingereicht',
    noRelatedLOsForLicence: 'Es sind keine Lernziele mit dieser Lizenz verknüpft',
    noRelevantLOAssigned: 'Keinem relevanten Lernziel zugewiesen',
    missingTranslation: 'Keine Übersetzung vorhanden',
    excludedLicence: 'Für Lizenz ausgeschlossen',
    invalidParent: 'Unzulässige Überebene',
    excessiveLearningObjectives: 'Überflüssige Lernziele',
    scriptIsMissingTranslationForCB: 'Das PDF kann nicht generiert werden, da das Skript mindestens einen content-block ohne Lokalisierung für die ausgewählte Sprache hat.',
  },
  suffix: {
    de: ' (DE)',
    en: ' (EN)',
  },
  state: {
    published: 'Veröffentlicht',
    unpublished: 'Nicht Veröffentlicht',
    edited: 'Bearbeitet',
  },
  questionnaireType: {
    training: 'Training',
    progress: 'Progress-Test',
    final: 'Final-Test',
    preExam: 'Pre-Exam',
  },
  productType: {
    accessPass: 'Zugangspass',
    education: 'Ausbildung',
  },
  publishedState: {
    pending: 'Wartend',
    published: 'Veröffentlicht',
    unpublished: 'Unveröffentlicht',
  },
  ticketType: {
    support: 'Support',
    task: 'Aufgabe',
    feedback: 'Feedback',
    review: 'Klärung',
    graphics: 'Grafiken',
    translation: 'Übersetzung',
    submission: 'Vorschlag',
  },
  taskType: {
    review: 'Review',
    default: 'Standard',
    translation: 'Übersetzung',
    graphic: 'Grafik',
    controlling: 'Buchhaltung',
  },
  taskStatus: {
    open: 'Offen',
    completed: 'Abgeschlossen',
    inProgress: 'In Bearbeitung',
  },
  feedbackType: {
    content: 'Feedback zu Inhalten',
    technical: 'Technisches Problem',
    featureRequest: 'Feature Wunsch',
    other: 'Sonstiges',
  },
  educationType: {
    presence: 'Präsenz',
    distance: 'Distanz',
  },
  accessPassType: {
    questionTrainer: 'Fragentrainer',
  },
  licenceType: {
    general: 'Generell',
    helicopter: 'Hubschrauber',
    aeroplane: 'Flugzeug',
    balloon: 'Ballon',
  },
  resourceType: {
    image: 'Bild',
    video: 'Video',
    audio: 'Audio',
    pdf: 'PDF',
    sketchFab: '3D Modell (SketchFab)',
    file: 'Datei',
    website: 'Webseite',
  },
  questionSource: {
    aircademy: 'Aircademy',
  },
  text: {
    questionFeedback: 'Hier kannst du Feedback zu der Frage angeben',
    changeLanguageInfo: 'Wähle deine bevorzugte Sprache für automatisch erstellte Emails und Kontakt durch unsere Kundenbetreuung.',
    confirmDeleteAccount: 'Willst du diesen Account wirklich entfernen?',
    confirmDeleteProfile: 'Willst du dieses Profil wirklich entfernen?',
    confirmDeleteComment: 'Willst du diesen Kommentar wirklich löschen?',
  },
  q: {
    passwordLost: 'Passwort vergessen?',
  },
  paymentMethod: {
    paypal: 'PayPal',
    invoice: 'Rechnung',
    freeOfCharge: 'Kostenlos',
  },
  orderStatus: {
    paid: 'Bezahlt',
    open: 'Offen',
    overdue: 'Überfällig',
    cancelled: 'Abgebrochen',
    refunded: 'Erstattet',
    freeOfCharge: 'Kostenlos',
  },
  invoiceStatus: {
    open: 'Offen',
    created: 'Erstellt',
    noInvoice: 'Keine Rechnung',
  },
  questionStatus: {
    active: 'Aktiv',
    archived: 'Archiviert',
  },
  analysis: {
    seriesName: {
      correct: 'Richtig',
      incorrect: 'Falsch',
      unanswered: 'Unbeantwortet',
    },
  },
  rule: {
    mustMatchPassword: 'Muss mit Passwort übereinstimmen',
    notEmpty: 'Darf nicht leer sein',
    positiveNumber: 'Muss eine positive Zahl sein',
    duplicateSelection: 'Eintrag existiert bereits',
  },
  ticketStatus: {
    open: 'Offen',
    closed: 'Geschlossen',
    inProgress: 'In Bearbeitung',
  },
  tooltip: {
    like: 'Like',
    dislike: 'Dislike',
  },
  draftStatus: {
    open: 'Ausstehend',
    accepted: 'Freigegeben',
    rejected: 'Abgelehnt',
  },
  color: {
    red: 'Rot',
    green: 'Grün',
    yellow: 'Gelb',
  },
  scriptLOState: {
    progressBar: '{count} von {total} Lernzielen sind {color} ({percentage})',
    parent: {
      red: 'Dieses Lernziel ist Rot, da alle untergeordneten Lernziele Rot sind.',
      orange: 'Dieses Lernziel ist Orange, da mindestens ein untergeordnetes Lernziele Rot oder Orange ist.',
      yellow: 'Dieses Lernziel ist Gelb, da mindestens ein untergeordnetes Lernziel Gelb ist.',
      green: 'Dieses Lernziel ist Grün, da alle untergeordneten Lernziele Grün sind.',
    },
    leaf: {
      red: 'Dieses Lernziel ist Rot, da es von keinem nummerierten Inhaltsblock behandelt wird.',
      green: 'Dieses Lernziel ist Grün, da es von genau einem nummerierten Inhaltsblock behandelt wird.',
      yellowMultipleCBs: 'Dieses Lernziel ist Gelb, da es von mehreren nummerierten Inhaltsblöcken behandelt wird.',
      yellowExcludedParent: 'Dieses Lernziel ist Gelb, da es von einem nummerierten Inhaltsblock behandelt wird, dessen übergeordneter Inhaltsblock für die ausgewählte Lizenz ausgeschlossen ist.',
      yellowNotTranslatedCBs: 'Dieses Lernziel ist Gelb, da es von mindestens einem nummerierten Inhaltsblock behandelt wird, der für die ausgewählte Sprache keine Lokalisierung hat.',
    },
  },
};
